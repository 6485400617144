.App {
}

.App-header {
  padding: 5px;
  text-align: center;
}
.App-logo {
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.Home-contents {
  max-width: 900px;
}
.Home-message {
  max-width: 600px;
}
.Home-categories {
  width: 300px;
}
.Home-category-wrap {
  width: 180px;
}
.Home-category {
  border-radius: 50%;
}

.App-detail-contents {
  max-width: 800px;
  margin-top: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.main-icon {
  width: 24rem;
}

.icon {
  width: 20px;
  height: 20px;
  display: inline;
}

h2 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.2rem;
}
